import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as matf from '@/helpers/matf'

const app = createApp(App).use(store).use(router);
Object.keys(matf).forEach(key => {
    app.config.globalProperties[`$${key}`] = matf[key];
});
app.mount('#app')