import axios from 'axios';
import store from './store'

//Create instance of axios
const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 422) {
        store.commit("setErrors", error.response.data.errors);
      } else if (error.response.status === 401) {
        store.commit("auth/setUserData", null);
        localStorage.removeItem("authToken");
        this.router.push({ name: "home" });
      } else {
        return Promise.reject(error);
      }
    }
);
  
instance.interceptors.request.use(function(config) {
    const token = localStorage.getItem("authToken");
    if(token){
        config.headers['Authorization'] = `Bearer ${token}`
    }
  
    return config;
});

export default instance;