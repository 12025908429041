<template>
  <div>
    <main class="d-flex flex-nowrap">
      <div class="sidebar p-3 bg-body-tertiary" style="width: 300px;">
        <div class="form-group">
          <label for="profile-system">Профильная система</label>
          <select
            id="profile-system"
            class="form-control"
            v-model="selectedProfileSystem"
          >
            <option v-for="item in profsys" :value="item.id" :key="item.id">{{item.profname}}</option>
            <!-- Добавьте опции для профилей системы, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="profile">Вид конструкции</label>
          <select
            id="profile"
            class="form-control"
            v-model="selectedConstrtypes"
          >
            <option v-for="item in constrTypes" :value="item.id" :key="item.id">{{item.name}}</option>
            <!-- Добавьте опции для видов конструкций, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="frame">Рама</label>
          <select
            id="frame"
            class="form-control"
            v-model="selectedFrame"
          >
            <option v-for="item in psFrames" :value="item.id" :key="item.id">{{item.articul}}</option>
            <!-- Добавьте опции для рам, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="sash">Створка</label>
          <select
            id="sash"
            class="form-control"
            v-model="selectedSash"
          >
            <option v-for="item in psSash" :value="item.id" :key="item.id">{{item.articul}}</option>
            <!-- Добавьте опции для створок, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="impost">Импост</label>
          <select
            id="impost"
            class="form-control"
            v-model="selectedImpost"
          >
            <option v-for="item in psImposts" :value="item.id" :key="item.id">{{item.articul}}</option>
            <!-- Добавьте опции для импостов, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="stub">Штульп</label>
          <select
            id="stub"
            class="form-control"
            v-model="selectedStub"
          >
            <option v-for="item in psStubs" :value="item.id" :key="item.id">{{item.articul}}</option>
            <!-- Добавьте опции для штульпов, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="sill">Порог</label>
          <select
            id="sill"
            class="form-control"
            v-model="selectedSill"
          >
            <option v-for="item in psSills" :value="item.id" :key="item.id">{{item.articul}}</option>
            <!-- Добавьте опции для порогов, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="chopstick">Штапик</label>
          <select
            id="chopstick"
            class="form-control"
            v-model="selectedChopstick"
          >
            <option v-for="item in psChopsticks" :value="item.id" :key="item.id">{{item.articul}}</option>
            <!-- Добавьте опции для штапиков, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="thickness">Толщина металла</label>
          <select
            id="thickness"
            class="form-control"
            v-model="thickness">
            <option value="">Толщина металла</option>
          <!-- Добавьте опции для толщины металла -->
          </select>
        </div>

        <div class="form-group">
          <label for="sashInSash">Створка в створке</label>
          <select
            id="sashInSash"
            class="form-control"
            v-model="selectedSashInSash"
          >
            <option v-for="item in psSashInSashs" :value="item.id" :key="item.id">{{item.articul}}</option>
            <!-- Добавьте опции для створок в створке, если они доступны -->
          </select>
        </div>

        <div class="form-group">
          <label for="hardware">Тип фурнитуры</label>
          <select
            id="hardware"
            class="form-control"
            v-model="hardware" >
          <option value="">Тип фурнитуры</option>
          <!-- Добавьте опции для фурнитуры -->
          </select>
        </div>

        <div class="form-group">
          <label for="handleHeight">Высота ручки</label>
          <select
            id="handleHeight"
            class="form-control"
            v-model="handleHeight" >
          <option value="">Высота ручки</option>
          <!-- Добавьте опции для высоты ручки -->
          </select>
        </div>

        <div class="form-group">
          <label for="sealingType">Тип уплотнения</label>
          <select
            id="sealingType"
            class="form-control"
            v-model="sealingType" >
          <option value="">Тип уплотнения</option>
          <!-- Добавьте опции для типа уплотнения -->
          </select>
        </div>

        <div class="form-group">
          <label for="overlayColor">Цвет накладок</label>
          <select
            id="overlayColor"
            class="form-control"
            v-model="overlayColor" >
          <option value="">Цвет накладок</option>
          <!-- Добавьте опции для цвета накладок -->
          </select>
        </div>

        <div class="form-group">
          <label for="handleColor">Цвет ручки</label>
          <select
            id="handleColor"
            class="form-control"
            v-model="handleColor">
          <option value="">Цвет ручки</option>
          <!-- Добавьте опции для цвета ручки -->
          </select>
        </div>

        <div class="form-group">
          <label for="glazingType">Вид стеклопакета</label>
          <select
            id="glazingType"
            class="form-control"
            v-model="glazingType" >
          <option value="">Вид стеклопакета</option>
          <!-- Добавьте опции для вида стеклопакета -->
          </select>
        </div>

        <div class="form-group">
          <label for="laminationType">Тип ламинации</label>
          <select
            id="laminationType"
            class="form-control"
            v-model="laminationType" >
          <option value="">Тип ламинации</option>
          <!-- Добавьте опции для типа ламинации -->
          </select>
        </div>

        <div class="form-group">
          <label for="color">Цвет</label>
          <select
            id="color"
            class="form-control"
            v-model="color" >
          <option value="">Цвет</option>
          <!-- Добавьте опции для цвета -->
          </select>
        </div>
      </div>
      <div class="constructor" ref="canvasContainer">
        <canvas id="constructor" :height="800" :width="800" ></canvas>
      </div>
    </main>
    <cModal
        id="sizeoutput"
        :visible="activeModal === 'sizeoutput'"
        @close="closeModal"
        title="Вывод размера"
        _class="modal-sm"
    >
      <template #default>
        <input type="number" class="form-control" v-model="sizeoutput">
      </template>
      <template #footer>
        <button  class="btn btn-primary">ok</button>
      </template>
    </cModal>
  </div>
</template>
<script >
import { mapGetters, mapActions, mapMutations } from "vuex";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import cModal from './cModal.vue';
import { getPolygons, calculateCentroid } from "../helpers/splitPolygons";

export default {
  name: "impostView",
  components: { cModal },
  data() {
    return {
      canvas: null,
      activeModal: null,
      boundingBox:[],
      scale:1,
      OFFSET:80,
      aRadius: null,
      arcHeight:null,
      frameLines:[],
      impostsLine:[],
      impostsMType: null,
      addImpostsNum: null,
      selImpostProf: null,
      dummyElement: null,
      addImpostsType: null,
      impostProfType: 1,
      connectors:{
        s1:null,
        s2:null,
        s3:null,
        s4:null,
        s5:null,
        s6:null,
        s7:null,
        s8:null,
      },
      fixLength:null,
      addToSize:null,
      dtt_:0,
      dtt:false,
      tt:0,
      tt_:false,
      selRama:0,
      setAll: null,
    }
  },
  created() {

  },
  computed: {
    ...mapGetters("profile", ["profsys","psFrames","psSash","psImposts","psStubs","psSills","psChopsticks","psSashInSashs"]),
    ...mapGetters("constr", ["dividers","addProfiles", "framePoints", "separators", "addProfs", "imposts", "dImposts", "dShtulp", "frames"]),
    constrTypes() {
      return this.$store.getters['profile/constrTypes']();
    },
    selectedProfileSystem: {
      get() {
        return this.$store.getters['profile/selected']("profsys");
      },
      set(value) {
        this.$store.commit('profile/setSelectedProfileSystem', value);
        this.$store.dispatch('profile/updateProfile', { param: 'profsys' });
      }
    },
    selectedConstrtypes: {
      get() {
        return this.$store.getters['profile/selected']("constrType");
      },
      set(value) {
        this.$store.commit('profile/setSelectedConstrTypes', value);
        this.$store.dispatch('profile/updateProfile', { param: 'constrType' });
      }
    },
    selectedFrame:{
      get() {
        return this.$store.getters['profile/selected']("frame");
      },
      set(value) {
        this.$store.commit('profile/setSelectedFrame', value);
      }
    },
    selectedSash:{
      get() {
        return this.$store.getters['profile/selected']("sash");
      },
      set(value) {
        this.$store.commit('profile/setSelectedSash', value);
      }
    },
    selectedImpost:{
      get() {
        return this.$store.getters['profile/selected']("impost");
      },
      set(value) {
        this.$store.commit('profile/setSelectedImpost', value);
      }
    },
    selectedStub:{
      get() {
        return this.$store.getters['profile/selected']("stub");
      },
      set(value) {
        this.$store.commit('profile/setSelectedStub', value);
      }
    },
    selectedSill:{
      get() {
        return this.$store.getters['profile/selected']("sill");
      },
      set(value) {
        this.$store.commit('profile/setSelectedSill', value);
      }
    },
    selectedChopstick:{
      get() {
        return this.$store.getters['profile/selected']("chopstick");
      },
      set(value) {
        this.$store.commit('profile/setSelectedChopstick', value);
      }
    },
    selectedSashInSash:{
      get() {
        return this.$store.getters['profile/selected']("sashInSash");
      },
      set(value) {
        this.$store.commit('profile/setSelectedSashInSash', value);
      }
    }
  },
  mounted() {
    // this.getDividers()
    // this.getaddProfiles()
    this.getProfsys()
    this.getConstrTypes()
    this.getImposts()
    this.getShtulp()
    this.getFrames()
    this.canvas = new fabric.Canvas('constructor', {
      selection: false,
      backgroundColor: '#ddd',
    });
    window.addEventListener('resize', this.resizeCanvas);
    this.resizeCanvas();
    this.clearCanvas()
    this.updateCanvas()
    this.canvas.on('mouse:down', (e) => this.mouseDown(e));
    this.canvas.on('mouse:move', (e) => this.objectMoving(e));
    this.canvas.on('mouse:up', (e) => this.mouseUp(e));
  },
  methods: {
    ...mapActions("profile", ["getProfsys", "getConstrTypes"]),
    ...mapActions("constr", ["getDividers","getaddProfiles","getImposts","getShtulp","getFrames"]),
    ...mapMutations("constr", ['updateStateItem', 'removeArrayItem', 'addFramePointinIndex','updateArrayItemProperty', 'addArrayItem']),
    drawFrame(){
      this.frameLines = []
      for (var i = 0; i < this.framePoints.length; i++) {
        var nextIndex = (i + 1) % this.framePoints.length;
        let R=this.framePoints[i].r;
        let pathData;
        if(this.framePoints[i].r){
          const t = this.framePoints[i].or < 0 ? 0 : 1;
          pathData = `
              M ${this.framePoints[i].x} ${this.framePoints[i].y}
              A ${R} ${R} 0 0 ${t} ${this.framePoints[nextIndex].x} ${this.framePoints[nextIndex].y}
              L ${this.framePoints[nextIndex].x1} ${this.framePoints[nextIndex].y1}
              A ${this.framePoints[i].r1} ${this.framePoints[i].r1} 0 0 ${t==1?0:1} ${this.framePoints[i].x1} ${this.framePoints[i].y1}
              Z
            `;
        }else{
          pathData = `
              M ${this.framePoints[i].x} ${this.framePoints[i].y}
              L ${this.framePoints[nextIndex].x} ${this.framePoints[nextIndex].y}
              L ${this.framePoints[nextIndex].x1} ${this.framePoints[nextIndex].y1}
              L ${this.framePoints[i].x1} ${this.framePoints[i].y1}
              Z
            `;
        }
        var path = new fabric.Path(pathData, {
          stroke: 'black',
          strokeWidth: 1,
          selectable: false,
          fill: 'white', // Фон текста
          lineIndex: i,
          ctype: 'frameLine'
        });
        this.canvas.add(path)
        var textelem, connector;
        if(this.framePoints[i].frame && this.framePoints[i].frame.connectors && (this.framePoints[i].frame.connectors.s1 || this.framePoints[i].frame.connectors.s2 || this.framePoints[i].frame.connectors.s3 || this.framePoints[i].frame.connectors.s4 || this.framePoints[i].frame.connectors.s5 ||this.framePoints[i].frame.connectors.s6 || this.framePoints[i].frame.connectors.s7 || this.framePoints[i].frame.connectors.s8)){
          if(this.framePoints[i].r){
            const coords = this.$getArcCoords(this.framePoints[i], this.framePoints[nextIndex]);
            connector = new fabric.Circle({
              left: coords.left,
              top: coords.top,
              radius: coords.radius,
              startAngle: coords.startAngle,
              endAngle: coords.endAngle,
              stroke: 'black',
              strokeWidth: 2,
              fill: '',
              angle: 0,
              selectable: false,
              lineIndex: i
            });
          }else{
            connector = new fabric.Line([this.framePoints[i].x, this.framePoints[i].y, this.framePoints[nextIndex].x, this.framePoints[nextIndex].y], {
              stroke: 'black', strokeWidth: 2, hasControls: false, selectable: false
            });
          }
          this.canvas.add(connector)
        }

        if(this.framePoints[i].frame && this.framePoints[i].frame.selRama){
          const prof = this.frames.find(obj => obj.id === this.framePoints[i].frame.selRama);
          if(this.framePoints[i].r){
            const coords = this.$getArcCoords(this.framePoints[i], this.framePoints[nextIndex]);
            textelem = this.$placeTextAlongArc(prof.or1_name.toString(), coords.left+coords.radius, coords.top+coords.radius, coords.radius, coords.startAngle, coords.endAngle)
          }else{
            var angle = Math.atan2(this.framePoints[nextIndex].y-this.framePoints[i].y, this.framePoints[nextIndex].x - this.framePoints[i].x);
            textelem = new fabric.Text(prof.or1_name.toString(), {
              left: (this.framePoints[nextIndex].x + this.framePoints[i].x)/2,
              top: (this.framePoints[nextIndex].y + this.framePoints[i].y)/2,
              fontSize: 16, // Размер текста
              fill: 'red', // Цвет текста
              originX: 'center',
              originY: 'center',
              backgroundColor: 'white', // Фон текста
              angle: angle * (180 / Math.PI), // Учет уклона линии
              selectable: false, // Разрешить выбор объекта
              evented: true // Включить обработку событий
            });
          }
          this.canvas.add(textelem)
        }
        this.frameLines.push(path)
      }
    },
    drawShtapics(points, index){
        for (var i = 0; i < points.length; i++) {
            const nextIndex = (i + 1) % points.length;
            const point = points[i];
            const nextPoint = points[nextIndex];
            let R = point.or ? Math.ceil(Math.abs(point.or) * this.scale * 1000) / 1000 : 0;
            let R1 = point.or1 ? Math.ceil(Math.abs(point.or1) * this.scale * 1000) / 1000 : 0;
            let pathData;
            if (R) {
                const t = point.or < 0 ? 0 : 1;
                pathData = `
              M ${this.getRendPoinst(point.ox, 'x')} ${this.getRendPoinst(point.oy, 'y')}
              A ${R} ${R} 0 0 ${t} ${this.getRendPoinst(nextPoint.ox, 'x')} ${this.getRendPoinst(nextPoint.oy, 'y')}
              L ${this.getRendPoinst(nextPoint.ox1, 'x')} ${this.getRendPoinst(nextPoint.oy1, 'y')}
              A ${R1} ${R1} 0 0 ${t === 1 ? 0 : 1} ${this.getRendPoinst(point.ox1, 'x')} ${this.getRendPoinst(point.oy1, 'y')}
              Z
            `;
            } else {
                pathData = `
              M ${this.getRendPoinst(point.ox, 'x')} ${this.getRendPoinst(point.oy, 'y')}
              L ${this.getRendPoinst(nextPoint.ox, 'x')} ${this.getRendPoinst(nextPoint.oy, 'y')}
              L ${this.getRendPoinst(nextPoint.ox1, 'x')} ${this.getRendPoinst(nextPoint.oy1, 'y')}
              L ${this.getRendPoinst(point.ox1, 'x')} ${this.getRendPoinst(point.oy1, 'y')}
              Z
            `;
            }
            const path = new fabric.Path(pathData, {
                stroke: 'black',
                strokeWidth: 1,
                selectable: false,
                fill: 'white', // Фон текста
                lineIndex: i,
            });
            this.canvas.add(path)
        }
    },
    drawGlassUnit(points, index){
        let beforePath = ' M ';
        let pathData = '';
        for (let i = 0; i < points.length; i++) {
            const point = points[i];
            const R = point.r ? Math.ceil(Math.abs(point.r) * this.scale * 1000) / 1000 : 0;
            const t = point.r < 0 ? 0 : 1;
            pathData += beforePath + this.getRendPoinst(point.x, 'x') + ' ' + this.getRendPoinst(point.y, 'y');
            beforePath = R ? ` A ${R} ${R} 0 0 ${t} ` : ' L ';
            if(i+1 === points.length && R){
                pathData += beforePath + this.getRendPoinst(points[0].x, 'x') + ' ' + this.getRendPoinst(points[0].y, 'y');
            }
        }
        pathData += ' Z';
        const path = new fabric.Path(pathData, {
            fill: '#a6caf0',
            selectable: false,
        });
        const {centerX, centerY} = calculateCentroid(points)
        const text = new fabric.Text(`${index}`, {
            left: this.getRendPoinst(centerX, 'x'),
            top: this.getRendPoinst(centerY, 'y'),
            fontSize: 16,
            fill: '#000000',
            backgroundColor: 'white',
            originX: 'center',
            originY: 'center',
            selectable: false
        });
        this.canvas.add(path);
        this.canvas.add(text);
    },
    renderGlazings(){
        let polygon = []
        for (let i = 0; i < this.framePoints.length; i++) {
            polygon.push({
                x: this.framePoints[i].ox1,
                y: this.framePoints[i].oy1,
                r: this.framePoints[i].or1
            })
        }
        const lines = [];
        for (let i = 0; i < this.separators.length; i++) {
            const ll = this.separators[i].drawLinesProf;
            lines.push([
                {
                    x: ll[0].x1,
                    y: ll[0].y1
                },
                {
                    x: ll[0].x2,
                    y: ll[0].y2
                },
                {
                    x: ll[1].x1,
                    y: ll[1].y1
                },
                {
                    x: ll[1].x2,
                    y: ll[1].y2
                },
            ])
        }
        for (let i = 0; i < this.imposts.length; i++) {
            const ll = this.imposts[i];
            const drawLines = ll?.drawLines;
            if(!drawLines || drawLines.length === 0){
                lines.push([
                    {
                        x: ll.ox1,
                        y: ll.oy1,
                        r: ll.or
                    },
                    {
                        x: ll.ox2,
                        y: ll.oy2,
                    }
                ])
                continue;
            }
           const replace1 = this.$getDistance(ll.ox1, ll.oy1, drawLines[0].x1, drawLines[0].y1) > this.$getDistance(ll.ox1, ll.oy1, drawLines[0].x2, drawLines[0].y2);
           const replace2 = this.$getDistance(ll.ox1, ll.oy1, drawLines[1].x1, drawLines[1].y1) > this.$getDistance(ll.ox1, ll.oy1, drawLines[1].x2, drawLines[1].y2);
            lines.push([
                {
                    x: replace1 ? drawLines[0].x2 : drawLines[0].x1,
                    y: replace1 ? drawLines[0].y2 : drawLines[0].y1,
                    r: ll.or
                },
                {
                    x: replace1 ? drawLines[0].x1 : drawLines[0].x2,
                    y: replace1 ? drawLines[0].y1 : drawLines[0].y2,
                },
              {
                x: replace2 ? drawLines[1].x2 : drawLines[1].x1,
                y: replace2 ? drawLines[1].y2 : drawLines[1].y1,
                r: ll.or
              },
              {
                x: replace2 ? drawLines[1].x1 : drawLines[1].x2,
                y: replace2 ? drawLines[1].y1 : drawLines[1].y2,
              },
            ])
        }
        const polygons = getPolygons(polygon, lines);
        for (let j = 0; j < polygons.length; j++) {
            this.drawGlassUnit(polygons[j], j+1)
            const polygon = polygons[j].map(obj => ({
                ox: obj.x,
                oy: obj.y,
                or: obj.r
            }));
            const shtapics = this.$getInsetPolygon(polygon, 20)
            this.drawShtapics(shtapics, j)
        }
    },
    clearCanvas(){
      this.selectedElement = null
      this.boundingBox = []
      this.canvas.clear();
      this.canvas.setBackgroundColor('#ddd');
    },
    updateCanvas(){
        this.updatePoints()
        this.drawFrame()
        this.renderSeparators()
        this.renderImposts()
        this.renderGlazings()
        this.drawBoundingBox()
    },
    resizeCanvas(e = false) {
      const container = this.$refs.canvasContainer;
      if(container){
        const containerWidth = Math.floor(container.clientWidth / 50) * 50;
        const containerHeight = Math.floor(container.clientHeight / 50) * 50;
        this.canvas.setWidth(containerWidth);
        this.canvas.setHeight(containerHeight);
        this.canvas.renderAll();
        if(e){
          this.clearCanvas()
          this.updateCanvas()
        }
      }
    },
    updatePoints(){
      const offset = 40;
      this.updateStateItem({property:'framePoints',value:this.$checkRadius(this.framePoints)})
      this.updateStateItem({property:'framePoints',value:this.$getInsetPolygon(this.framePoints, offset)})
      this.updateSeparators();
      this.$store.dispatch('constr/updateImposts');
      const spr = this.$getScalePoinst(this.framePoints, this.canvas.width, this.canvas.height, this.OFFSET, this.separators)
      this.scale = spr.scale;
      this.updateStateItem({property:'framePoints',value:spr.points})
      this.updateStateItem({property:'separators',value:spr.separators})
      this.mx = spr.mx;
      this.left = spr.left;
      this.top = spr.top;
    },
    mouseUp(e){
      this.isMouseDown = false
    },
    mouseDown(e){
        this.isMouseDown = true
    },
    objectMoving(e){
      if (!this.selectedElement || !this.isMouseDown) return;

    },
    updateSeparators(){
      var _self = this;
      let lines = this.getAllLines('o');
      this.separators.map(function(line, i) {
        const prof = line.prof ? _self.dividers.find(obj => obj.id === line.prof) : null;
        let distance = prof && (!line.fixedDimension || parseInt(line.fixedDimension) < 1) ? prof.wr : parseInt(line.fixedDimension)*2;
        var data = _self.$lineLinesIntersections(line, lines);
        if(data.length){
          if(data[0] && _self.separators[i].ox2 == data[0].x && _self.separators[i].oy2 == data[0].y && data[1]){
            _self.separators[i].ox1 = data[1].x
            _self.separators[i].oy1 = data[1].y
          }else if(data[0] && _self.separators[i].ox1 == data[0].x && _self.separators[i].oy1 == data[0].y && data[1]){
            _self.separators[i].ox2 = data[1].x
            _self.separators[i].oy2 = data[1].y
          }else if(data[1] && _self.separators[i].ox1 == data[1].x && _self.separators[i].oy1 == data[1].y && data[0]){
            _self.separators[i].ox2 = data[0].x
            _self.separators[i].oy2 = data[0].y
          }else if(data[1] && _self.separators[i].ox2 == data[1].x && _self.separators[i].oy2 == data[1].y && data[0]){
            _self.separators[i].ox1 = data[0].x
            _self.separators[i].oy1 = data[0].y
          }else{
            if(data[0]){
              _self.separators[i].ox1 = data[0].x
              _self.separators[i].oy1 = data[0].y
            }
            if(data[1]){
              _self.separators[i].ox2 = data[1].x
              _self.separators[i].oy2 = data[1].y
            }
          }
        }
        if(distance >= 10 && !line.notIncDimensions){
          const mlp = _self.$moveLinePerpendicular(line.ox1, line.oy1, line.ox2, line.oy2, distance, _self.getRoundedSteps(distance, 20))
          _self.separators[i].drawLines = []
          mlp.map(function(linelp, j) {
            var newL = {
              ox1:linelp.x1,
              oy1:linelp.y1,
              ox2:linelp.x2,
              oy2:linelp.y2,
              lt: '-'
            }
            var data = _self.$lineLinesIntersections(newL, lines);
            if(data.length){
              if(data[0] && newL.ox2 == data[0].x && newL.oy2 == data[0].y && data[1]){
                newL.ox1 = data[1].x
                newL.oy1 = data[1].y
                newL.or1 = data[1].line ? data[1].line.r : null
              }else if(data[0] && newL.ox1 == data[0].x && newL.oy1 == data[0].y && data[1]){
                newL.ox2 = data[1].x
                newL.oy2 = data[1].y
                newL.or2 = data[1].line ? data[1].line.r : null
              }else if(data[1] && newL.ox1 == data[1].x && newL.oy1 == data[1].y && data[0]){
                newL.ox2 = data[0].x
                newL.oy2 = data[0].y
                newL.or2 = data[0].line ? data[0].line.r : null
              }else if(data[1] && newL.ox2 == data[1].x && newL.oy2 == data[1].y && data[0]){
                newL.ox1 = data[0].x
                newL.oy1 = data[0].y
                newL.or1 = data[0].line ? data[0].line.r : null
              }else{
                if(data[0]){
                  newL.ox1 = data[0].x
                  newL.oy1 = data[0].y
                  newL.or1 = data[0].line ? data[0].line.r : null
                }
                if(data[1]){
                  newL.ox2 = data[1].x
                  newL.oy2 = data[1].y
                  newL.or2 = data[1].line ? data[1].line.r : null
                }
              }
            }
            newL = _self.$normalizeLine({
              x1:newL.ox1,
              y1:newL.oy1,
              x2:newL.ox2,
              y2:newL.oy2
            })
            _self.separators[i].drawLines.push(newL)
            if (j === 0 || j === mlp.length - 1) {
              lines.push({
                index:i,
                lt: '-',
                ...newL
              })
            }
          })
        }else{
          lines.push({
            x1:_self.separators[i].ox1,
            y1:_self.separators[i].oy1,
            x2:_self.separators[i].ox2,
            y2:_self.separators[i].oy2,
            index:i
          })
          _self.separators[i].drawLines = []
        }
      })
      var lines1 = this.getAllLines('o','1');
      const offset = 40;
      this.separators.map(function(line, i) {
        const prof = line.prof ? _self.dividers.find(obj => obj.id === line.prof) : null;
        let distance = prof && (!line.fixedDimension || parseInt(line.fixedDimension) < 1) ? prof.wr : parseInt(line.fixedDimension)*2;
        distance = distance >=10 && !line.notIncDimensions ? distance + 2*offset :2*offset;
        const mlp = _self.$moveLinePerpendicular(line.ox1, line.oy1, line.ox2, line.oy2, distance, 2)
        _self.separators[i].drawLinesProf = []
        mlp.map(function(linelp, jj) {
          const linelpShort =  _self.$shortenLine(linelp.x1,linelp.y1,linelp.x2,linelp.y2, offset)
          var newL = {
            ox1:linelpShort.x1,
            oy1:linelpShort.y1,
            ox2:linelpShort.x2,
            oy2:linelpShort.y2,
            lt: '-'
          }
          var data = _self.$lineLinesIntersections(newL, lines1);
          if(data.length){
            if(data[0] && newL.ox2 == data[0].x && newL.oy2 == data[0].y && data[1]){
              newL.ox1 = data[1].x
              newL.oy1 = data[1].y
              newL.or1 = data[1].line ? data[1].line.r : null
            }else if(data[0] && newL.ox1 == data[0].x && newL.oy1 == data[0].y && data[1]){
              newL.ox2 = data[1].x
              newL.oy2 = data[1].y
              newL.or2 = data[1].line ? data[1].line.r : null
            }else if(data[1] && newL.ox1 == data[1].x && newL.oy1 == data[1].y && data[0]){
              newL.ox2 = data[0].x
              newL.oy2 = data[0].y
              newL.or2 = data[0].line ? data[0].line.r : null
            }else if(data[1] && newL.ox2 == data[1].x && newL.oy2 == data[1].y && data[0]){
              newL.ox1 = data[0].x
              newL.oy1 = data[0].y
              newL.or1 = data[0].line ? data[0].line.r : null
            }else{
              if(data[0]){
                newL.ox1 = data[0].x
                newL.oy1 = data[0].y
                newL.or1 = data[0].line ? data[0].line.r : null
              }
              if(data[1]){
                newL.ox2 = data[1].x
                newL.oy2 = data[1].y
                newL.or2 = data[1].line ? data[1].line.r : null
              }
            }
          }
          newL = _self.$normalizeLine({
            x1:newL.ox1,
            y1:newL.oy1,
            x2:newL.ox2,
            y2:newL.oy2
          })
          _self.separators[i].drawLinesProf.push(newL)
          if (jj === 0 || jj === mlp.length - 1) {
            lines1.push({
              index:i,
              lt: '-',
              ...newL
            })
          }
        })
      })
    },
    renderImposts(type){
      const _self = this;
      this.impostsLine = []
      this.imposts.map(function(point, index) {
          const prof = point.prof ? point.profType == 1 ? _self.dImposts.find(obj => obj.id === point.prof) : _self.dShtulp.find(obj => obj.id === point.prof) : null;
          const x1 = _self.getRendPoinst(point.ox1, 'x')
          const x2 = _self.getRendPoinst(point.ox2, 'x')
          const y1 = _self.getRendPoinst(point.oy1, 'y')
          const y2 = _self.getRendPoinst(point.oy2, 'y')
          const aCoords = point.or ? _self.$getArcCoords(
              {x: x1, y: y1, or: point.or, r: Math.ceil(Math.abs(point.or) * _self.scale * 1000) / 1000},
              {x: x2, y: y2}) : null;
          let line;
          const R = point.or ? Math.ceil(Math.abs(point.or) * _self.scale * 1000) / 1000 : 0;
          if (point.drawLines && point.drawLines.length) {
              const line1 = {
                x1: point.drawLines[0].x1,
                y1: point.drawLines[0].y1,
                x2: point.drawLines[0].x2,
                y2: point.drawLines[0].y2,
              };
              const replace = _self.$getDistance(line1.x1, line1.y1, point.drawLines[1].x1, point.drawLines[1].y1) > _self.$getDistance(line1.x1, line1.y1, point.drawLines[1].x2, point.drawLines[1].y2);
              const line2 = {
                x1: replace ? point.drawLines[1].x2 : point.drawLines[1].x1,
                y1: replace ? point.drawLines[1].y2 : point.drawLines[1].y1,
                x2: replace ? point.drawLines[1].x1 : point.drawLines[1].x2,
                y2: replace ? point.drawLines[1].y1 : point.drawLines[1].y2
              }
              const replaseCenter = _self.$getDistance(line1.x1, line1.y1, x1, y1) > _self.$getDistance(line1.x1, line1.y1, x2, y2);
              const lineC = {
                x1: replaseCenter ? x2 : x1,
                y1: replaseCenter ? y2 : y1,
                x2: replaseCenter ? x1 : x2,
                y2: replaseCenter ? y1 : y2
              }
              let pathData;
              if (point.or) {
                  const t = point.or < 0 ? 0 : 1;
                  pathData = `
                  M ${_self.getRendPoinst(line1.x1, 'x')} ${_self.getRendPoinst(line1.y1, 'y')}
                  A ${R} ${R} 0 0 ${t} ${_self.getRendPoinst(line1.x2, 'x')} ${_self.getRendPoinst(line1.y2, 'y')}
                  L ${lineC.x2} ${lineC.y2}
                  L ${_self.getRendPoinst(line2.x2, 'x')} ${_self.getRendPoinst(line2.y2, 'y')}
                  A ${R} ${R} 0 0 ${t === 1 ? 0 : 1} ${_self.getRendPoinst(line2.x1, 'x')} ${_self.getRendPoinst(line2.y1, 'y')}
                  L ${lineC.x1} ${lineC.y1}
                  Z
                `;
              } else {
                  pathData = `
                  M ${_self.getRendPoinst(line1.x1, 'x')} ${_self.getRendPoinst(line1.y1, 'y')}
                  L ${_self.getRendPoinst(line1.x2, 'x')} ${_self.getRendPoinst(line1.y2, 'y')}
                  L ${lineC.x2} ${lineC.y2}
                  L ${_self.getRendPoinst(line2.x2, 'x')} ${_self.getRendPoinst(line2.y2, 'y')}
                  L ${_self.getRendPoinst(line2.x1, 'x')} ${_self.getRendPoinst(line2.y1, 'y')}
                  L ${lineC.x1} ${lineC.y1}
                  Z
                `;
              }
              line = new fabric.Path(pathData, {
                  stroke: 'black',
                  strokeWidth: 1,
                  selectable: false,
                  fill: 'white', // Фон текста
                  lineIndex: index,
                  ctype: 'imposts'
              });
          } else {
              if (point.or) {
                  line = new fabric.Circle({
                      left: aCoords.left,
                      top: aCoords.top,
                      radius: aCoords.radius,
                      startAngle: aCoords.startAngle,
                      endAngle: aCoords.endAngle,
                      stroke: 'black',
                      strokeWidth: 3,
                      fill: '',
                      angle: 0,
                      selectable: false,
                      lineIndex: index,
                      ctype: 'imposts'
                  });
              } else {
                  line = new fabric.Line([x1, y1, x2, y2], {
                      stroke: 'black',
                      strokeWidth: 3,
                      hasControls: false,
                      selectable: false,
                      lineIndex: index,
                      ctype: 'imposts'
                  });
              }
          }
          _self.canvas.add(line)
          _self.impostsLine.push(line)
      })
    },
    renderSeparators(type){
      var _self = this;
      this.separatorsLine=[];
      this.separators.map(function(point, index) {
        var line;
        if(point.drawLines && point.drawLines.length){
          let _points = []
          let drawLines = point.drawLines
          const normalizPoint = _self.$normalizeLine({
            x1:point.x1,
            y1:point.y1,
            x2:point.x2,
            y2:point.y2
          })
          var pp;
          for (let i = 0; i < drawLines.length; i++) {
            if(i*2 == drawLines.length){
              _points.push({x:normalizPoint.x1,y:normalizPoint.y1});
            }
            pp = {x:_self.getRendPoinst(drawLines[i].x1, 'x'),y:_self.getRendPoinst(drawLines[i].y1, 'y')};
            _points.push(pp)
          }
          for (let i = drawLines.length - 1; i >= 0; i--) {
            pp = {x:_self.getRendPoinst(drawLines[i].x2, 'x'),y:_self.getRendPoinst(drawLines[i].y2, 'y')};
            _points.push(pp)
            if(i*2 == drawLines.length){
              _points.push({x:normalizPoint.x2,y:normalizPoint.y2});
            }
          }
          const color = point.dummyElement ? 'red' : 'grey';
          line = _self.$drawPolygon(_points, 'separators', index, color, '#dddddd');
        }else{
          line = new fabric.Line([point.x1, point.y1, point.x2, point.y2], {
            stroke: '#4d4d4d',
            strokeWidth: 1,
            hasControls: false,
            selectable: false,
            lineIndex: index,
            ctype: 'separators'
          });
        }
        var leftpathProf,rightpathProf;
        if(point.drawLinesProf && point.drawLinesProf.length){
          const normalizPoint1 = point.drawLines && point.drawLines.length ? {
            x1:_self.getRendPoinst(point.drawLines[0].x1, 'x'),
            y1:_self.getRendPoinst(point.drawLines[0].y1, 'y'),
            x2:_self.getRendPoinst(point.drawLines[0].x2, 'x'),
            y2:_self.getRendPoinst(point.drawLines[0].y2, 'y')
          } : _self.$normalizeLine({
            x1:point.x1,
            y1:point.y1,
            x2:point.x2,
            y2:point.y2
          })
          const normalizPoint2 = point.drawLines && point.drawLines.length ?  {
            x1:_self.getRendPoinst(point.drawLines.at(-1).x1, 'x'),
            y1:_self.getRendPoinst(point.drawLines.at(-1).y1, 'y'),
            x2:_self.getRendPoinst(point.drawLines.at(-1).x2, 'x'),
            y2:_self.getRendPoinst(point.drawLines.at(-1).y2, 'y')
          } : _self.$normalizeLine({
            x1:point.x1,
            y1:point.y1,
            x2:point.x2,
            y2:point.y2
          })
          leftpathProf = new fabric.Path(`
                M ${normalizPoint1.x1} ${normalizPoint1.y1}
                L ${normalizPoint1.x2} ${normalizPoint1.y2}
                L ${_self.getRendPoinst(point.drawLinesProf[0].x2, 'x')} ${_self.getRendPoinst(point.drawLinesProf[0].y2, 'y')}
                L ${_self.getRendPoinst(point.drawLinesProf[0].x1, 'x')} ${_self.getRendPoinst(point.drawLinesProf[0].y1, 'y')}
                Z
              `, {
            stroke: 'black',
            strokeWidth: 1,
            selectable: false,
            fill: 'white', // Фон текста
          });
          rightpathProf = new fabric.Path(`
                M ${normalizPoint2.x1} ${normalizPoint2.y1}
                L ${normalizPoint2.x2} ${normalizPoint2.y2}
                L ${_self.getRendPoinst(point.drawLinesProf[1].x2, 'x')} ${_self.getRendPoinst(point.drawLinesProf[1].y2, 'y')}
                L ${_self.getRendPoinst(point.drawLinesProf[1].x1, 'x')} ${_self.getRendPoinst(point.drawLinesProf[1].y1, 'y')}
                Z
              `, {
            stroke: 'black',
            strokeWidth: 1,
            selectable: false,
            fill: 'white', // Фон текста
          });
          _self.canvas.add(leftpathProf,rightpathProf)
        }
        _self.canvas.add(line)
        _self.separatorsLine.push({line,leftpathProf,rightpathProf})
      });
    },
    drawBoundingBox() {
      // Удаляем предыдущий ограничивающий прямоугольник, если он существует
      if (this.boundingBox.length) {
        this.boundingBox.forEach(element => {
          this.canvas.remove(element);
        });
        this.boundingBox=[];
      }

      // Находим минимальные и максимальные координаты всех объектов с учетом радиусов
      const {minX, minY, maxX, maxY} = this.$minMaxCords(this.framePoints);
      this.minMax = {minX, minY, maxX, maxY};
      var uniqueX = {},uniqueY = {};
      this.framePoints.forEach(point => {
        uniqueX['k'+point.ox] = point.x
        uniqueY['k'+point.oy] = point.y
      });
      uniqueX['k'+this.mx.minX] = this.minMax.minX
      uniqueX['k'+this.mx.maxX] = this.minMax.maxX
      uniqueY['k'+this.mx.minY] = this.minMax.minY
      uniqueY['k'+this.mx.maxY] = this.minMax.maxY
      uniqueX = Object.entries(uniqueX).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
      uniqueY = Object.entries(uniqueY).sort((a, b) => { return Number(a[1]) - Number(b[1]);});

      this.createArrowWithLength(minY, maxY, minX, 60, Math.abs(this.mx.minY - this.mx.maxY), 'y', 1);
      this.createArrowWithLength(minX, maxX,  minY, 60, Math.abs(this.mx.minX - this.mx.maxX), 'x', 1);
      var length;
      if(uniqueY.length > 2){
        for (let iy = 0; iy < uniqueY.length; iy++) {
          length = Math.abs(parseFloat(uniqueY[iy][0].slice(1)) - parseFloat(uniqueY[iy+1][0].slice(1)));
          this.createArrowWithLength(uniqueY[iy][1], uniqueY[iy+1][1], minX, 40, length, 'y');
          if(iy+2 == uniqueY.length) break;
        }
      }
      if(uniqueX.length > 2){
        for (let ix = 0; ix < uniqueX.length; ix++) {
          length = Math.abs(parseFloat(uniqueX[ix][0].slice(1)) - parseFloat(uniqueX[ix+1][0].slice(1)));
          this.createArrowWithLength(uniqueX[ix][1], uniqueX[ix+1][1], minY, 40, length, 'x');
          if(ix+2 == uniqueX.length) break;
        }
      }

      var uniquesX = {},uniquesY = {};
      this.separators.forEach(point => {
        uniquesX['k'+Math.trunc(point.ox1)] = point.x1
        uniquesX['k'+Math.trunc(point.ox2)] = point.x2
        uniquesY['k'+Math.trunc(point.oy1)] = point.y1
        uniquesY['k'+Math.trunc(point.oy2)] = point.y2
      });
      uniquesX['k'+Math.trunc(this.mx.minX)] = this.minMax.minX
      uniquesX['k'+Math.trunc(this.mx.maxX)] = this.minMax.maxX
      uniquesY['k'+Math.trunc(this.mx.minY)] = this.minMax.minY
      uniquesY['k'+Math.trunc(this.mx.maxY)] = this.minMax.maxY
      uniquesX = Object.entries(uniquesX).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
      uniquesY = Object.entries(uniquesY).sort((a, b) => { return Number(a[1]) - Number(b[1]);});
      if(uniquesX.length > 2){
        for (let sx = 0; sx < uniquesX.length; sx++) {
          length = Math.abs(parseFloat(uniquesX[sx][0].slice(1)) - parseFloat(uniquesX[sx+1][0].slice(1)));
          this.createArrowWithLength(uniquesX[sx][1], uniquesX[sx+1][1], maxY, -40, length, 'x', 3);
          if(sx+2 == uniquesX.length) break;
        }
      }
      if(uniquesY.length > 2){
        for (let sy = 0; sy < uniquesY.length; sy++) {
          length = Math.abs(parseFloat(uniquesY[sy][0].slice(1)) - parseFloat(uniquesY[sy+1][0].slice(1)));
          this.createArrowWithLength(uniquesY[sy][1], uniquesY[sy+1][1], maxX, -40, length, 'y', 3);
          if(sy+2 == uniquesY.length) break;
        }
      }

    },
    createArrowWithLength(from, to, min, d, length, type, all=2) {
      length = parseFloat(length.toFixed(1))
      const fromX = type == 'x' ? from : min-d;
      const fromY = type == 'y' ? from : min-d;
      const toX = type == 'x' ? to : min-d;
      const toY = type == 'y' ? to : min-d;

      var angle = Math.atan2(toY - fromY, toX - fromX);
      var headLength = 10; // Длина стрелки

      // Координаты стрелок на концах
      var arrowX1 = toX - headLength * Math.cos(angle - Math.PI / 6);
      var arrowY1 = toY - headLength * Math.sin(angle - Math.PI / 6);
      var arrowX2 = toX - headLength * Math.cos(angle + Math.PI / 6);
      var arrowY2 = toY - headLength * Math.sin(angle + Math.PI / 6);

      var arrowX3 = fromX + headLength * Math.cos(angle - Math.PI / 6);
      var arrowY3 = fromY + headLength * Math.sin(angle - Math.PI / 6);
      var arrowX4 = fromX + headLength * Math.cos(angle + Math.PI / 6);
      var arrowY4 = fromY + headLength * Math.sin(angle + Math.PI / 6);

      var line = new fabric.Line([fromX, fromY, toX, toY], {
        fill: 'black',
        stroke: 'black',
        strokeWidth: 1,
        selectable: false // Невозможно выбрать объект
      });

      var arrowHead1 = new fabric.Polygon([
        { x: toX, y: toY },
        { x: arrowX1, y: arrowY1 },
        { x: arrowX2, y: arrowY2 }
      ], {
        fill: 'black',
        selectable: false // Невозможно выбрать объект
      });

      var arrowHead2 = new fabric.Polygon([
        { x: fromX, y: fromY },
        { x: arrowX3, y: arrowY3 },
        { x: arrowX4, y: arrowY4 }
      ], {
        fill: 'black',
        selectable: false // Невозможно выбрать объект
      });

      const lline =  new fabric.Group([line, arrowHead1, arrowHead2], {
        selectable: false, // Невозможно выбрать объект
        hasControls: false
      });

      // Создаем текстовый элемент с фоном
      var text = new fabric.Text(length.toString(), {
        left: (fromX + toX) / 2,
        top: (fromY + toY) / 2,
        fontSize: 12, // Размер текста
        fill: 'red', // Цвет текста
        backgroundColor: 'white', // Фон текста
        originX: 'center',
        originY: 'center',
        angle: angle * 180 / Math.PI, // Учет уклона линии
        selectable: false, // Разрешить выбор объекта
        evented: true // Включить обработку событий
      });
      text.on('mousedown', (e) => this.onTextClick(e, to, from, type, all, length));

      this.boundingBox.push(lline,text)
      this.canvas.add(lline,text);
    },
    onTextClick(e, to, from, type, all, length){
      this.editLengthData = {e, to, from, type, all, len:length}
      this.sizeoutputType = 'editLength'
      this.sizeoutput = length;
      this.openModal('sizeoutput')
    },
    onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    },
    openModal(id) {
      this.activeModal = id;
    },
    closeModal(id) {
      if (this.activeModal === id) {
        this.activeModal = null;
      }
    },
    addUniqueNumber(arr, num) {
      // Проверка, есть ли в массиве число, которое на 1 больше или меньше
      const exists = arr.some(item => Math.abs(item - num) <= 1);

      // Если такого числа нет, добавляем новое число в массив
      if (!exists) {
        arr.push(num);
      }

      return arr;
    },
    cFixed(num, decimals=2) {
      // Проверяем, является ли число целым
      if (Number.isInteger(num)) {
        return num; // Для целых чисел возвращаем число без изменений
      }

      // Если число дробное, используем toFixed для округления
      return num.toFixed(decimals);
    },
    getOrigPoinst(val, type){
      if(type == 'x'){
        return (val - this.OFFSET - this.left) / this.scale + this.mx.minX
      }
      if(type == 'y'){
        return (val - this.OFFSET - this.top) / this.scale + this.mx.minY
      }
    },
    getRendPoinst(val, type){
      if(type == 'x'){
        return (val - this.mx.minX) * this.scale + this.OFFSET + this.left
      }
      if(type == 'y'){
        return (val - this.mx.minY) * this.scale + this.OFFSET + this.top
      }
    },
    getRoundedSteps(distance, d) {
      let steps = Math.floor(distance / d);
      if (steps % 2 !== 0) {
        steps--; // Если количество шагов нечетное, уменьшаем на 1
      }
      return Math.max(steps, 2);
    },
    getAllLines(k='',k1=''){
      var lines = []
      this.framePoints.forEach((point, index) => {
        var nextIndex = (index + 1) % this.framePoints.length;
        lines.push({
          x1:this.framePoints[index][k+'x'+k1],
          y1:this.framePoints[index][k+'y'+k1],
          x2:this.framePoints[nextIndex][k+'x'+k1],
          y2:this.framePoints[nextIndex][k+'y'+k1],
          r:this.framePoints[index][k+'r'+k1]
        })
      })
      return lines;
    }

  }
}
</script>
<style scoped>
.form-control, .form-select{
  padding: 2px!important;
}
.form-group {
  text-align: left;
  margin-bottom: 15px;
}
</style>