import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import modelView from '../views/modelView.vue'
import frameView from '../views/frameView.vue'
import impostView from '../views/impostView.vue'
import sashView from '../views/sashView.vue'
import impostSashView from '../views/impostSashView.vue'
import sashSashView from '../views/sashSashView.vue'
import chopsticksView from '../views/chopsticksView.vue'
import dgwbView from '../views/dgwbView.vue'
import totalView from '../views/totalView.vue'

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/model");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/");
  }
};

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: guest,
    component: HomeView
  },
  {
    path: '/model',
    name: 'Модель',
    beforeEnter: auth,
    component: modelView
  },
  {
    path: '/frame',
    name: 'Рама',
    beforeEnter: auth,
    component: frameView
  },
  {
    path: '/impost',
    name: 'Импост',
    beforeEnter: auth,
    component: impostView
  },
  {
    path: '/sash',
    name: 'Створка',
    beforeEnter: auth,
    component: sashView
  },
  {
    path: '/impost_in_sash',
    name: 'Импост в створке',
    beforeEnter: auth,
    component: impostSashView
  },
  {
    path: '/sash_in_sash',
    name: 'Створка в створке',
    beforeEnter: auth,
    component: sashSashView
  },
  {
    path: '/chopsticks',
    name: 'Штапики',
    beforeEnter: auth,
    component: chopsticksView
  },
  {
    path: '/double-glazed_windows_and_bars',
    name: 'Стеклопакеты и шпросы',
    beforeEnter: auth,
    component: dgwbView
  },
  {
    path: '/total',
    name: 'Итог',
    beforeEnter: auth,
    component: totalView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router