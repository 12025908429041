<template>
  <div v-if="visible" class="modal fade show" @click="closeOnOverlay">
    <div class="modal-dialog modal-dialog-centered" :class="_class" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" v-if="title">{{title}}</h1>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      required: false
    },
    _class: {
      type: String,
      required: false
    },
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close', this.id);
    },
    closeOnOverlay() {
      if (this.closeOnOverlayClick) {
        this.close();
      }
    }
  }
};
</script>

<style scoped>
.modal.fade.show {
  display: block;
}
.modal-footer {
    justify-content: space-between;
}
</style>
