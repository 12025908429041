import { createStore } from 'vuex'
import auth from "./modules/auth";
import constr from "./modules/constr";
import profile from "./modules/profile";

export default createStore({
  state: {
    errors: []
  },
  getters: {
    errors: state => state.errors
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    }
  },
  actions: {
  },
  modules: {
    auth,
    constr,
    profile
  }
})
