import caxios from "@/axios.js"
import { lineLinesIntersections, normalizeLineO, moveLinePerpendicular } from '../../helpers/matf'
export default {
  namespaced: true,

  state: {
    dividers: null,
    dImposts: null,
    dShtulp: null,
    addProfiles: null,
    framePoints: [
      { ox: 0, oy: 0, or: 900},
      { ox: 1000, oy: 0},
      { ox: 1000, oy: 1000 },
      { ox: 0, oy: 1000 }
    ],
    separators:[],
    addProfs:[],
    imposts:[],
    frames:[],
    selectedProfile:{}
  },

  getters: {
    dividers: state => state.dividers,
    dImposts: state => state.dImposts,
    dShtulp: state => state.dShtulp,
    addProfiles: state => state.addProfiles,
    framePoints: state => state.framePoints,
    separators: state => state.separators,
    addProfs: state => state.addProfs,
    imposts: state => state.imposts,
    frames: state => state.frames,
    getAllLines: (state) => (k = '', k1 = '') => {
      let lines = [];
      state.framePoints.forEach((point, index) => {
        let nextIndex = (index + 1) % state.framePoints.length;
        lines.push({
          x1: state.framePoints[index][`${k}x${k1}`],
          y1: state.framePoints[index][`${k}y${k1}`],
          x2: state.framePoints[nextIndex][`${k}x${k1}`],
          y2: state.framePoints[nextIndex][`${k}y${k1}`],
          r: state.framePoints[index][`${k}r${k1}`]
        });
      });
      return lines;
    }
  },
  mutations: {
    setDividers(state, data) {
      state.dividers = data;
    },
    setaddProfiles(state, data) {
      state.addProfiles = data;
    },
    setImposts(state, data) {
      state.dImposts = data;
    },
    setShtulp(state, data) {
      state.dShtulp = data;
    },
    setFrames(state, data) {
      state.frames = data;
    },
    updateStateItem(state, {property, value}) {
      if (Object.prototype.hasOwnProperty.call(state, property)){
        state[property] = value;
      }
    },
    addFramePointinIndex(state, {point, k}) {
      state.framePoints.splice(k, 0, point);
    },
    removeArrayItem(state, {property, index}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]){
        state[property].splice(index, 1);
      }
    },
    updateArrayItem(state, {property, index, item}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]){
        state[property][index]=item;
      }
    },
    addArrayItem(state, {property, item}){
      if (Object.prototype.hasOwnProperty.call(state, property)){
        state[property].push(item);
      }
    },
    updateArrayItemProperty(state, {property, index, key, value}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]) {
        state[property][index][key] = value;
      }
    }
  },
  actions: {
    updateImposts({ state, commit, getters }, payload={}){
      let lines = getters.getAllLines('o', '1');
      state.separators.map(function(line, i) {
        if(line.drawLinesProf){
          for (let i = 0; i < line.drawLinesProf.length; i++) {
            lines.push(line.drawLinesProf[i])
          }
        }
      })
      state.imposts.map(function(line, i) {
        const prof = line.prof ? line.profType === 1 ? getters.dImposts.find(obj => obj.id === line.prof) : getters.dShtulp.find(obj => obj.id === line.prof) : null;
        let distance = prof ? prof.wr : 40;
        const data = lineLinesIntersections(line, lines);
        let copyLine = {...line}
        if(data.length){
          if(data[0] && line.ox2 === data[0].x && line.oy2 === data[0].y && data[1]){
            copyLine.ox1 = data[1].x
            copyLine.oy1 = data[1].y
          }else if(data[0] && line.ox1 === data[0].x && line.oy1 === data[0].y && data[1]){
            copyLine.ox2 = data[1].x
            copyLine.oy2 = data[1].y
          }else if(data[1] && line.ox1 === data[1].x && line.oy1 === data[1].y && data[0]){
            copyLine.ox2 = data[0].x
            copyLine.oy2 = data[0].y
          }else if(data[1] && line.ox2 === data[1].x && line.oy2 === data[1].y && data[0]){
            copyLine.ox1 = data[0].x
            copyLine.oy1 = data[0].y
          }else{
            if(data[0]){
              copyLine.ox1 = data[0].x
              copyLine.oy1 = data[0].y
            }
            if(data[1]){
              copyLine.ox2 = data[1].x
              copyLine.oy2 = data[1].y
            }
          }
        }
        // copyLine = normalizeLineO(copyLine)
        commit('updateArrayItem', {property: 'imposts', index: i, item: copyLine})
        if(distance >= 10){
          const mlp = moveLinePerpendicular(copyLine.ox1, copyLine.oy1, copyLine.ox2, copyLine.oy2, distance, 2)
          state.imposts[i].drawLines = []
          mlp.map(function(lineup, j) {
            let newL = {
              ox1: lineup.x1,
              oy1: lineup.y1,
              ox2: lineup.x2,
              oy2: lineup.y2,
              lt: '-'
            };
            const data = lineLinesIntersections(newL, lines);
            if(data.length){
              if(data[0] && newL.ox2 === data[0].x && newL.oy2 == data[0].y && data[1]){
                newL.ox1 = data[1].x
                newL.oy1 = data[1].y
                newL.or1 = data[1].line ? data[1].line.r : null
              }else if(data[0] && newL.ox1 === data[0].x && newL.oy1 === data[0].y && data[1]){
                newL.ox2 = data[1].x
                newL.oy2 = data[1].y
                newL.or2 = data[1].line ? data[1].line.r : null
              }else if(data[1] && newL.ox1 === data[1].x && newL.oy1 === data[1].y && data[0]){
                newL.ox2 = data[0].x
                newL.oy2 = data[0].y
                newL.or2 = data[0].line ? data[0].line.r : null
              }else if(data[1] && newL.ox2 === data[1].x && newL.oy2 === data[1].y && data[0]){
                newL.ox1 = data[0].x
                newL.oy1 = data[0].y
                newL.or1 = data[0].line ? data[0].line.r : null
              }else{
                if(data[0]){
                  newL.ox1 = data[0].x
                  newL.oy1 = data[0].y
                  newL.or1 = data[0].line ? data[0].line.r : null
                }
                if(data[1]){
                  newL.ox2 = data[1].x
                  newL.oy2 = data[1].y
                  newL.or2 = data[1].line ? data[1].line.r : null
                }
              }
            }
            newL = {
              x1:newL.ox1,
              y1:newL.oy1,
              x2:newL.ox2,
              y2:newL.oy2
            }
            state.imposts[i].drawLines.push(newL)
            if (j === 0 || j === mlp.length - 1) {
              lines.push({
                index:i,
                lt: '-',
                r:line?.or,
                ...newL
              })
            }
          })
        }else{
          lines.push({
            x1:copyLine.ox1,
            y1:copyLine.oy1,
            x2:copyLine.ox2,
            y2:copyLine.oy2,
            r:line?.or,
            index:i
          })
          state.imposts[i].drawLines = []
        }
      })
    },
    getDividers({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=17&count=all&sortCol=wr")
        .then(response => {
          commit("setDividers", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getaddProfiles({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=17&count=all")
        .then(response => {
          commit("setaddProfiles", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getImposts({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=6&count=all&sortCol=wr")
        .then(response => {
          commit("setImposts", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getShtulp({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=14&count=all&sortCol=wr")
        .then(response => {
          commit("setShtulp", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getFrames({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=14&count=all&sortCol=wr")
        .then(response => {
          commit("setFrames", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    }
  }
};