<template>
  <div class="login mt-5">
    <div class="card">
      <div class="card-header">
        Login
      </div>
      <div class="card-body">
        <form>
          <div class="form-group mb-3">
            <label for="email">Email address</label>
            <input
              type="email"
              class="form-control"
              :class="{ 'is-invalid': errors.email }"
              id="email"
              v-model="details.email"
              placeholder="Enter email"
            />
            <div class="invalid-feedback" v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>
          <div class="form-group mb-3">
            <label for="password">Password</label>
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': errors.password }"
              id="password"
              v-model="details.password"
              placeholder="Password"
            />
            <div class="invalid-feedback" v-if="errors.password">
              {{ errors.password[0] }}
            </div>
          </div>
          <div class="alert alert-danger" v-if="errors.message">
              {{ errors.message }}
          </div>
          <div class="d-grid gap-2">
            <button type="button" @click="login" class="btn btn-primary" :disabled="loading">
              <span class="spinner-border spinner-border-sm" aria-hidden="true" v-if="loading"></span>
              <span role="status" v-if="loading">Loading...</span>
              <span role="status" v-else>Login</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomeView",
  data: function() {
    return {
      loading: false,
      details: {
        email: null,
        password: null
      }
    };
  },

  computed: {
    ...mapGetters(["errors"])
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),

    login: function() {
      this.loading = true;
      this.sendLoginRequest(this.details).then(() => {
        this.loading = false;
        this.$router.push({ path: "model" });
      })
      .catch(() => {
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
  .login{
    width: 400px;
    max-width: 90%;
    text-align: left;
    margin: auto;
  }
</style>