import caxios from "@/axios.js"
export default {
    namespaced: true,
    state: {
        selected:{
            profsys: null,
            constrType: null,
            frame: null,
            sash: null,
            impost: null,
            stub: null,
            sill: null,
            chopstick: null,
            sashInSash:null
        },
        profsys:[],
        constrTypes:[],
        psFrames:[],
        psSash:[],
        psImposts: [],
        psStubs:[],
        psSills:[],
        psChopsticks:[],
        psSashInSashs:[]
    },

    getters: {
        selected:state => key => state.selected[key],
        profsys: state => state.profsys,
        constrTypes: (state) => () => {
            if(!state.selected.profsys) return []

            return state.constrTypes.filter(obj =>
                obj.profsys_ids.split(',').map(Number).includes(state.selected.profsys)
            );

        },
        psFrames: state => state.psFrames,
        psSash: state => state.psSash,
        psImposts: state => state.psImposts,
        psStubs: state => state.psStubs,
        psSills: state => state.psSills,
        psChopsticks: state => state.psChopsticks,
        psSashInSashs: state => state.psSashInSashs,
    },
    mutations: {
        setSelectedProfileSystem(state, value) {
            state.selected.profsys = value;
            // Прямая фильтрация без геттеров
            const filteredConstrTypes = state.constrTypes.filter(obj =>
                obj.profsys_ids.split(',').map(Number).includes(value)
            );
            // Установка значения constrType
            state.selected.constrType = filteredConstrTypes.length > 0
                ? filteredConstrTypes[0].id
                : null;
        },
        setSelectedConstrTypes(state, value) {
            state.selected.constrType = value;
        },
        setSelectedFrame(state, value) {
            state.selected.frame = value;
        },
        setSelectedSash(state, value) {
            state.selected.sash = value;
        },
        setSelectedImpost(state, value) {
            state.selected.impost = value;
        },
        setSelectedStub(state, value) {
            state.selected.stub = value;
        },
        setSelectedSill(state, value) {
            state.selected.sill = value;
        },
        setSelectedChopstick(state, value) {
            state.selected.chopstick = value;
        },
        setSelectedSashInSash(state, value) {
            state.selected.sashInSash = value;
        }
    },
    actions: {
        updateProfile({ dispatch, commit }, payload) {
            dispatch('getFrames');
            dispatch('getSash');
            dispatch('getImposts');
            dispatch('getStubs');
            dispatch('getSills');
            dispatch('getChopsticks');
            dispatch('getSashInSash');
        },
        getProfsys({ state }) {
            if(state.profsys.length > 0) return;
            caxios
                .get("/getProfsys?count=all")
                .then(response => {
                    state.profsys = response.data.body.items;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        getConstrTypes({ state }) {
            if(state.constrTypes.length > 0) return;
            caxios
                .get("/getConstrtypes?count=all")
                .then(response => {
                    state.constrTypes = response.data.body.items;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        getFrames({ state }) {
            state.psFrames = [];
            state.selected.frame = null;
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            caxios
                .get(`/getArticuls?profTypeId=2&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.psFrames = response.data.body.items;
                    state.selected.frame = response.data.body.items[0]?.id;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        getSash({ state }) {
            state.psSash = [];
            state.selected.sash = null;
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            caxios
                .get(`/getArticuls?profTypeId=7&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.psSash = response.data.body.items;
                    state.selected.sash = response.data.body.items[0]?.id;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        getImposts({ state }) {
            state.psImposts = [];
            state.selected.impost = null;
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            caxios
                .get(`/getArticuls?profTypeId=6&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.psImposts = response.data.body.items;
                    state.selected.impost = response.data.body.items[0]?.id;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        getStubs({ state }) {
            state.psStubs = [];
            state.selected.stub = null;
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            caxios
                .get(`/getArticuls?profTypeId=14&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.psStubs = response.data.body.items;
                    state.selected.stub = response.data.body.items[0]?.id;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        getSills({ state }) {
            state.psSills = [];
            state.selected.sill = null;
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            caxios
                .get(`/getArticuls?profTypeId=5&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.psSills = response.data.body.items;
                    state.selected.sill = response.data.body.items[0]?.id;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        getChopsticks({ state }) {
            state.psChopsticks = [];
            state.selected.chopstick = null;
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            caxios
                .get(`/getArticuls?profTypeId=8,15&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.psChopsticks = response.data.body.items;
                    state.selected.chopstick = response.data.body.items[0]?.id;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        getSashInSash({state}) {
            state.psSashInSashs = [];
            state.selected.sashInSash = null;
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            caxios
                .get(`/getArticuls?profTypeId=16&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.psSashInSashs = response.data.body.items;
                    state.selected.sashInSash = response.data.body.items[0]?.id;
                })
                .catch(() => {
                    console.log('Error')
                });
        }
    }
};