import caxios from "@/axios.js"

export default {
  namespaced: true,

  state: {
    userData: null
  },

  getters: {
    user: state => state.userData
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    }
  },

  actions: {
    getUserData({ commit }) {
      caxios
        .get("me")
        .then(response => {
          commit("setUserData", response.data.body.user);
        })
        .catch(() => {
          localStorage.removeItem("authToken");
        });
    },
    sendLoginRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return caxios
        .post("login", data)
        .then(response => {
          if(!response) return;

          if(response.data.user && response.data.token){
            commit("setUserData", response.data.user);
            localStorage.setItem("authToken", response.data.token);
          }
          if(response.data.errors){
            commit("setErrors", response.data.errors, { root: true });
          }
        });
    },
    sendLogoutRequest({ commit }) {
      return caxios.get("logout").then(() => {
        commit("setUserData", null);
        localStorage.removeItem("authToken");
      });
    },
  }
};